// import { Col, Row } from 'antd';
import { siteConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import './LandingImage.styles.less';
import { ILandingImageProps } from "./LandingImage.types";

export const LandingImage = (props: ILandingImageProps) =>
    // <Row className="LandingImage">
    //     <Col>
    //         <div
    //             className={!!props.class ? `landing-image ${props.class}` : "landing-image"}
    //             style={{ backgroundImage: `url(${siteConfig.imageBase}${props.imageUrl})` }}
    //         >
    //             {props.children}
    //         </div>
    //     </Col>
    // </Row>
    <div className='LandingImage'>
        <img className={!!props.class ? `landing-image ${props.class}` : "landing-image"} src={`${siteConfig.imageBase}${props.imageUrl}`} />
    </div>