// Place for defining consts which needs to be passed to shared/common components
export const contactFormKey = "z1r_cu";
export const gAnalyticsId = "UA-18535300-1";
export const gaMeasurementId = 'G-M9CDMSR51L';

// export const localHostUrl   = "local.z1r.com";
export const localHostUrl   = "localhost:3000";
export const localSiteUrl   = `http://${localHostUrl}`;
export const localApiUrl    = localSiteUrl;
export const localImageBase = localSiteUrl;
// export const localApiUrl    = "https://stage2021.z1r.com";
// export const localImageBase = "https://stage2021.z1r.com";
// export const localApiUrl    = "http://local.z1r.com";
// export const localImageBase = "http://local.z1r.com";

export const stageHostUrl   = `stage.z1r.com`;
export const stageSiteUrl   = `https://${stageHostUrl}`;
export const stageApiUrl    = stageSiteUrl;
export const stageImageBase = stageSiteUrl;

export const readyHostUrl   = `ready.z1r.com`;
export const readySiteUrl   = `https://${readyHostUrl}`;
export const readyApiUrl    = readySiteUrl;
export const readyImageBase = readySiteUrl;

export const prodHostUrl   = `www.z1r.com`;
export const prodSiteUrl   = `https://${prodHostUrl}`;
export const prodApiUrl    = prodSiteUrl;
export const prodImageBase = prodSiteUrl;

export const navTemplate = "nav2021";
export const catalogTemplate = "catalog";
export const categoryTemplate = "category2021";
export const partTemplate = "part";
export const prodTemplate = "product2021";
export const prodGrpTemplate = "ProductGroup2020";

export const siteKey = "6LfFwgseAAAAADgJ9em3PDHLF1j8d5eOMIYE0-ax";
export const prodMapsKey = "AIzaSyCsSq9nOdBVtvf0cXEPvNeYDaUChUZG12U";   // Z1R
export const devMapsKey = "AIzaSyCsSq9nOdBVtvf0cXEPvNeYDaUChUZG12U";    // TODO: needs to update this once Aron creates the new dev only key
export const youTubeKey = "AIzaSyASiPkZJdx_3RIFKDIdzO7fbRmu9ouT3K4";    // Z1R
export const youTubePlaylistId = "UUdgDpKSIeQr2WLkzmJsrxrA";
export const brandName = "Z1R";
export const spinnerLogo = "";

export const facebookUrl = "https://www.facebook.com/z1rofficial";
export const instagramUrl = "https://www.instagram.com/z1r_official/";
export const twitterUrl = "https://twitter.com/officialz1r";
export const youTubeUrl = "https://www.youtube.com/channel/UC0sxQNTj54qb-9FvVudWAeQ/featured";
export const tikTokUrl = "https://www.tiktok.com/@z1r_official";

// Settings for Pixlee Instagram feed
export const pixlee = {
    // apiKey: "3ojJM9nW0elvfuAbTiXk",
    // widgetId: "35657",
    apiKey: "N6H2jlcL03gsc2HySvlB",    
    widgetId: "37125",
};

// <div id="pixlee_container"></div><script type="text/javascript">window.PixleeAsyncInit = function() {Pixlee.init({apiKey:'N6H2jlcL03gsc2HySvlB'});Pixlee.addSimpleWidget({widgetId:'37125'});};</script><script src="//instafeed.assets.pxlecdn.com/assets/pixlee_widget_1_0_0.js"></script>