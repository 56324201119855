import * as React from 'react';
import './ProductAccessories.styles.less';
import {IProductAccessoriesProps} from "./ProductAccessories.types";
import { Col, Row } from 'antd';
import { imageUrl } from 'theme/libs/util';
import { Link } from 'react-router-dom';

export const ProductAccessories = (props:IProductAccessoriesProps) => {
    return (
        <>
        <Row>
            <Col span={12} push={props.displayReverse ? 12 : 0}>
                <img className='prodAccessoryImage'
                    src={imageUrl(props.image)}
                />
            </Col>
            <Col span={12} pull={props.displayReverse ? 12 : 0}>
                <div className={props.displayReverse ? 'displayReverse copyTextParent' : 'copyTextParent'}>
                    <div className='copyTextChild' dangerouslySetInnerHTML={{ __html: props.copyBlock }} />  
                    <br />
                    <Link to={props.link}>{props.buttonText}</Link>
                </div>       
            </Col>
        </Row>
        </>
    )
}